import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BudgetsService } from '../../../services/budgets/budgets.service';
import { Budget } from '../../../interfaces/budget-interfaces';
import { PropertyDetailsService } from '../../../services/property-details/property-details.service';
import { Transaction } from '../../../interfaces/property';
import {
    addYears,
    endOfDay,
    isSameDay,
    isWithinInterval,
    startOfDay,
} from 'date-fns';

@Component({
    selector: 'app-income',
    templateUrl: './income.component.html',
    styleUrls: ['./income.component.css'],
})
export class IncomeComponent implements OnInit {
    loading: boolean = true;
    updating = true;
    totalValue = 0;
    budgetYears: string[] = [];
    currentBudgetYear!: string;
    nextBudgetYear!: string;
    selectedYears: string = '';
    selectedBudgets!: Budget[];
    allUnits: Transaction[] = [];
    totalRec!: string;
    totalBoiler!: string;
    totalService!: string;
    totalExp!: string;
    totalOut!: string;

    constructor(
        public budgetService: BudgetsService,
        public propertyDetails: PropertyDetailsService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;
        try {
            const budgetResponse = await firstValueFrom(
                await this.budgetService.getAllYears()
            );

            const budgetYear = this.budgetService.calculateBudgetYear();
            this.nextBudgetYear =
                (+budgetYear + 1).toString() +
                '-' +
                (+budgetYear + 2).toString();
            this.budgetYears = budgetResponse
                .map((budget) => budget.budgetYear)
                .filter((budget) => budget !== this.nextBudgetYear);
            this.currentBudgetYear =
                this.budgetYears.find(
                    (budget) => budget.split('-')[0] === budgetYear
                ) || '';
            this.selectedYears = this.currentBudgetYear;

            this.allUnits = await firstValueFrom(
                this.propertyDetails.getAllTrans()
            );
            await this.updateSelectedBudgets();

            this.loading = false;
        } catch {
            this.loading = false;
        }
    }

    async updateSelectedBudgets() {
        this.updating = true;
        this.selectedBudgets = await firstValueFrom(
            await this.budgetService.getSelectedBudgets([this.selectedYears])
        );
        this.selectedBudgets = this.selectedBudgets.sort(
            (a, b) => +a.budgetYear.split('-')[0] - +b.budgetYear.split('-')[0]
        );
        this.totalRec = await this.calculateTotalReceived();
        this.totalBoiler = await this.calcTotalBoiler();
        this.totalService = await this.calculateTotal(
            this.selectedBudgets[0],
            ''
        );
        this.totalExp = (+this.totalBoiler + +this.totalService).toFixed(2);
        this.totalOut = (+this.totalExp - +this.totalRec).toFixed(2);
        this.updating = false;
    }

    async calculateTotal(budget: Budget, code: string): Promise<string> {
        return budget.budgetValues
            .filter((head) => head.code.includes(code))
            .map((head) => head.value)
            .reduce((a, b) => a + b || 0, 0)
            .toString();
    }

    calcTotalBoiler = async () => {
        const budgetYear = this.selectedYears.split('-')[0];
        const startDate = new Date(+budgetYear, 8, 1);
        const endDate = addYears(startDate, 1);

        return this.allUnits
            .filter(
                (trans) =>
                    !!trans &&
                    isSameDay(new Date(trans.date), new Date(startDate))
            )
            .filter(
                (trans: Transaction | undefined) =>
                    !!trans &&
                    trans.transactionType === 'Charge' &&
                    trans.description === 'Boiler Servicing Scheme'
            )
            .reduce((a, b) => a + (b?.amount ?? 0), 0)
            .toFixed(2);
    };

    async calculateTotalReceived() {
        const budgetYear = this.selectedYears.split('-')[0];
        const startDate = startOfDay(new Date(+budgetYear, 8, 1));
        const endDate = endOfDay(new Date(+budgetYear + 1, 7, 31));

        return this.allUnits
            .filter(
                (trans) =>
                    !!trans &&
                    isWithinInterval(new Date(trans.date), {
                        start: startDate,
                        end: endDate,
                    })
            )
            .filter(
                (trans: Transaction | undefined) =>
                    !!trans &&
                    ['Payment', 'Refund Payment'].includes(
                        trans.transactionType
                    )
            )
            .reduce(
                (total, trans) =>
                    trans?.transactionType === 'Payment'
                        ? total + trans?.amount
                        : total - (trans?.amount ?? 0),
                0
            )
            .toFixed(2);
    }
}
