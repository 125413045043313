import { Component, OnInit } from '@angular/core';
import { BudgetsService } from '../../../services/budgets/budgets.service';
import { firstValueFrom } from 'rxjs';
import { Budget } from '../../../interfaces/budget-interfaces';
import { HttpClient } from '@angular/common/http';
import { PropertyDetailsService } from '../../../services/property-details/property-details.service';
import { AuthServiceService } from '../../../services/auth-service/auth-service.service';
import { AppConfigService } from '../../../services/app-config/app-config.service';
import { BudgetHeadConst } from '../../../constants/budgets';
import { Schedule } from '../../../interfaces/config-interface';
import { startCase } from 'lodash';
import { Router } from '@angular/router';

@Component({
    selector: 'app-budgets',
    templateUrl: './budgets.component.html',
    styleUrls: ['./budgets.component.css'],
})
export class BudgetsComponent implements OnInit {
    loading = true;
    updating = true;
    totalValue = 0;
    budgetYears: string[] = [];
    currentBudgetYear!: string;
    nextBudgetYear!: string;
    userGroups: (string | number)[] = [];
    budgetHeads: BudgetHeadConst[] = [];
    reserveHeads: Schedule[] = [];
    selectedBudgets!: (Budget | undefined)[];
    selectedYears: string[] = ['-', '-', '-'];
    tabSelected: string = 'heads';
    reserveOpen: boolean = false;

    constructor(
        public budgetService: BudgetsService,
        public propertyService: PropertyDetailsService,
        public http: HttpClient,
        public auth: AuthServiceService,
        public appConfig: AppConfigService,
        public router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;
        try {
            this.budgetHeads = [
                ...this.appConfig?.config?.budgetData?.heads,
            ].sort((a, b) => a.name.localeCompare(b.name));
            this.reserveHeads = this.appConfig?.config?.budgetData?.schedules;
            const budgetResponse = await firstValueFrom(
                await this.budgetService.getAllYears()
            );
            this.userGroups = await this.auth.getGroups();
            const budgetYear: string =
                this.appConfig.config.budgetData.currentYear;

            this.nextBudgetYear = budgetYear
                .split('-')
                .map((year) => +year + 1)
                .join('-');

            this.budgetYears = budgetResponse
                .map((budget) => budget.budgetYear)
                .filter((budget) => budget !== this.nextBudgetYear)
                .sort((a, b) => +a.split('-')[0] - +b.split('-')[0]);

            this.currentBudgetYear = budgetYear;
            this.selectedYears[2] = this.currentBudgetYear;
            await this.updateSelectedBudgets();
        } catch (error: any) {
            console.error(error);
        } finally {
            this.loading = false;
        }
    }

    getModelValue(head: { code: any }, budget: { budgetValues: any[] }) {
        return budget.budgetValues.find(
            (budgetCodes) => budgetCodes.code === head.code
        ).value;
    }

    async updateSelectedBudgets() {
        let budgetResponse: Budget[] = [];

        this.updating = true;
        budgetResponse = await firstValueFrom(
            await this.budgetService.getSelectedBudgets([
                ...new Set(this.selectedYears.filter((year) => year !== '-')),
            ])
        );

        this.selectedBudgets = this.selectedYears.map(
            (year) =>
                budgetResponse.find((budget) => year === budget.budgetYear) ??
                undefined
        );

        this.updating = false;
    }

    calculateTotal(budget: Budget, code: string): string {
        return budget.budgetValues
            .filter((head) => head.code.includes(code))
            .map((head) => head.value)
            .reduce((a, b) => a + b || 0, 0)
            .toString();
    }

    calculateCatValue(subCats: any[] | undefined, budget: Budget): string {
        const catCodes = subCats?.map((cat) => cat.code) || [];
        return budget.budgetValues
            .filter((head) => catCodes.includes(head.code))
            .reduce((a, b) => a + b.value, 0)
            .toString();
    }

    protected readonly startCase = startCase;

    async goToEditBudget() {
        await this.router.navigateByUrl('/home-page/edit-budget');
    }

    calculateScheduleTotals(budget: Budget, code: Schedule) {
        return budget?.breakdown
            ?.map((breakdown) => breakdown[code.code])
            .filter((value) => value)
            .reduce((a, b) => +a + +b, 0);
    }
}
