import { Component, OnInit } from '@angular/core';
import { BudgetsService } from '../../services/budgets/budgets.service';
import { firstValueFrom } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AppConfigService } from '../../services/app-config/app-config.service';

@Component({
    selector: 'app-admin-panel',
    templateUrl: './admin-panel.component.html',
    styleUrls: ['./admin-panel.component.css'],
})
export class AdminPanelComponent implements OnInit {
    apiKey = environment.apiKey;
    apiBaseUrl = environment.apiBaseUrl;
    budgetYears!: string[];
    budgetForm: FormGroup = new FormGroup({
        budgetYear: new FormControl(null, Validators.required),
        month: new FormControl(null, Validators.required),
    });
    applyChargesForm: FormGroup = new FormGroup({
        budgetYear: new FormControl(null, Validators.required),
        month: new FormControl(null, Validators.required),
    });
    loading: boolean = false;
    check: {} | undefined;

    constructor(
        public budgetService: BudgetsService,
        public http: HttpClient,
        public appConfig: AppConfigService
    ) {}

    async ngOnInit(): Promise<void> {
        const budgetResponse = await firstValueFrom(
            await this.budgetService.getAllYears()
        );
        this.budgetYears = budgetResponse
            .map((budget) => budget.budgetYear)
            .sort((a, b) => +a.split('-')[0] - +b.split('-')[0]);
    }

    async generateCharges() {
        this.loading = true;
        try {
            const { budgetYear, month } = this.budgetForm.value;
            let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
            let params = new HttpParams()
                .append('budgetYear', budgetYear)
                .append('month', month);
            await firstValueFrom(
                this.http.get(
                    this.apiBaseUrl + '/batch/generateservicecharge',
                    {
                        headers,
                        params,
                    }
                )
            );
        } catch {
        } finally {
            this.loading = false;
        }
    }

    async applyCharges() {
        this.loading = true;
        try {
            const { budgetYear, month } = this.applyChargesForm.value;
            let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
            let params = new HttpParams()
                .append('budgetYear', budgetYear)
                .append('month', month)
                .append('site', this.appConfig.selectedSite);

            await firstValueFrom(
                this.http.get(this.apiBaseUrl + '/batch/applycharges', {
                    headers,
                    params,
                })
            );
        } catch {
        } finally {
            this.loading = false;
        }
    }

    async checkCharges() {
        this.loading = true;
        try {
            const { budgetYear, month } = this.applyChargesForm.value;
            let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
            let params = new HttpParams()
                .append('budgetYear', budgetYear)
                .append('month', month)
                .append('site', this.appConfig.selectedSite);
            this.check = await firstValueFrom(
                this.http.get(this.apiBaseUrl + '/batch/checkcharges', {
                    headers,
                    params,
                })
            );
        } catch {
        } finally {
            this.loading = false;
        }
    }
}
