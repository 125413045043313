<app-page-toolbar [pageTitle]="'Budgets'"></app-page-toolbar>

<div class="mx-auto flex w-full max-w-7xl items-start flex-col">
  <app-cw-card class="flex-1 p-0 m-0 mobile:w-full mt-5" [showFooter]="false">
    <card-icon class="w-full">
      <div class="w-full">
        <div class="block w-full">
          <nav class="flex space-x-1 w-full" aria-label="Tabs">
            <a (click)="tabSelected='heads'"
               [ngClass]="tabSelected === 'heads' ? 'bg-cw-blue text-white':'hover:text-gray-700'"
               class="rounded-md px-3 py-2 text-sm font-medium cursor-pointer w-[33%] text-center">Budget</a>
            <a (click)="tabSelected='reserves'"
               [ngClass]="tabSelected === 'reserves' ? 'bg-cw-blue text-white':'hover:text-gray-700'"
               class="rounded-md px-3 py-2 text-sm font-medium cursor-pointer w-[33%] text-center">Reserves</a>
            <a (click)="tabSelected='breakdown'"
               [ngClass]="tabSelected === 'breakdown' ? 'bg-cw-blue text-white':'hover:text-gray-700'"
               class="rounded-md px-3 py-2 text-sm font-medium cursor-pointer w-[33%] text-center">Schedule
              Breakdown</a>
          </nav>
        </div>
      </div>
    </card-icon>

    <card-body>
      <div [hidden]="tabSelected !== 'heads'">
        <div class="">
          <div class=" flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block  py-2 align-middle sm:px-6 lg:px-8 w-full">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                    <tr>
                      <th scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[400px]">
                        Categories
                      </th>
                      <th scope="col" class="pr-6 py-3.5 text-right text-sm font-semibold text-gray-900 w-[150px]"
                          *ngFor="let budget of selectedBudgets">
                        {{ budget?.budgetYear }}
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                    <ng-container *ngFor="let head of budgetHeads; index as i">

                      <ng-container *ngIf="selectedBudgets && !head?.subCats" class="hover:bg-gray-100">
                        <tr
                          class="hover:bg-gray-100">
                          <td class="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6">

                            {{ head.name }}

                          </td>
                          <td *ngFor="let budget of selectedBudgets"
                              class=" px-3 py-1 text-xs text-gray-500 text-right w-[150px]"><input *ngIf="budget"
                                                                                                   class="text-gray-900 w-[150px] border-none bg-transparent"
                                                                                                   currencyMask
                                                                                                   [options]="{ prefix: '£ ' }"
                                                                                                   matInput
                                                                                                   autocomplete="off"
                                                                                                   placeholder="0.00"
                                                                                                   [value]="getModelValue(head, budget)"
                                                                                                   disabled
                          />
                          </td>
                        </tr>
                      </ng-container>
                      <ng-container *ngIf="selectedBudgets && head?.subCats">
                        <tr
                          class="hover:bg-gray-100">
                          <td colspan="4">
                            <table class="min-w-full">
                              <thead>
                              <tr>
                                <th scope="col"
                                    class=" pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6 w-[400px]">

                                  {{ head.name }}

                                </th>
                                <th scope="col"
                                    class="px-3 text-sm text-gray-900 text-right w-[150px]"
                                    *ngFor="let budget of selectedBudgets">
                                  <input *ngIf="budget" class="text-gray-900 w-[150px] border-none bg-transparent"
                                         currencyMask
                                         [options]="{ prefix: '£ ' }"
                                         matInput
                                         autocomplete="off"
                                         placeholder="0.00"
                                         [value]="calculateCatValue(
                                                    head?.subCats,
                                                    budget
                                                )"
                                         disabled
                                  />
                                </th>

                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let subCat of head.subCats"
                                  class="hover:bg-gray-100">
                                <td
                                  class="whitespace-nowrap pl-6 pr-3 text-xs text-gray-900 sm:pl-6 w-[400px]">

                                  - {{ subCat.name }}

                                </td>
                                <td *ngFor="let budget of selectedBudgets"
                                    class=" px-3 text-xs text-gray-500 text-right"><input *ngIf="budget"
                                                                                          class="text-gray-900 w-[150px] border-none bg-transparent"
                                                                                          currencyMask
                                                                                          [options]="{ prefix: '£ ' }"
                                                                                          matInput
                                                                                          autocomplete="off"
                                                                                          placeholder="0.00"
                                                                                          [value]="getModelValue(subCat, budget)"
                                                                                          disabled
                                />
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </td>


                        </tr>


                      </ng-container>

                    </ng-container>


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div [hidden]="tabSelected !== 'reserves'">
        <div class="">
          <div class=" flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block  py-2 align-middle sm:px-6 lg:px-8 w-full">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                    <tr>
                      <th scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[400px]">
                        Categories
                      </th>
                      <th scope="col" class="pr-6 py-3.5 text-right text-sm font-semibold text-gray-900 w-[150px]"
                          *ngFor="let budget of selectedBudgets">
                        {{ budget?.budgetYear }}
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                    <ng-container *ngFor="let head of reserveHeads">

                      <ng-container *ngIf="selectedBudgets" class="hover:bg-gray-100">
                        <tr
                          class="hover:bg-gray-100">
                          <td class="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6">

                            {{ head.name }}

                          </td>
                          <td *ngFor="let budget of selectedBudgets"
                              class=" px-3 py-1 text-xs text-gray-500 text-right w-[150px]"><input *ngIf="budget"
                                                                                                   class="text-gray-900 w-[150px] border-none bg-transparent"
                                                                                                   currencyMask
                                                                                                   [options]="{ prefix: '£ ' }"
                                                                                                   matInput
                                                                                                   autocomplete="off"
                                                                                                   placeholder="0.00"
                                                                                                   [value]="getModelValue(head, budget)"
                                                                                                   disabled
                          />
                          </td>
                        </tr>
                      </ng-container>


                    </ng-container>


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div [hidden]="tabSelected !== 'breakdown'">
        <div class="">
          <div class=" flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block  py-2 align-middle sm:px-6 lg:px-8 w-full">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                    <tr>
                      <th scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[400px]">
                        Categories
                      </th>
                      <th scope="col" class="pr-6 py-3.5 text-right text-sm font-semibold text-gray-900 w-[150px]"
                          *ngFor="let budget of selectedBudgets">
                        {{ budget?.budgetYear }}
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                    <ng-container *ngFor="let head of reserveHeads">

                      <ng-container *ngIf="selectedBudgets" class="hover:bg-gray-100">
                        <tr
                          class="hover:bg-gray-100">
                          <td class="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6">

                            {{ head.name }}

                          </td>
                          <td *ngFor="let budget of selectedBudgets"
                              class=" px-3 py-1 text-xs text-gray-500 text-right w-[150px]"><input *ngIf="budget"
                                                                                                   class="text-gray-900 w-[150px] border-none bg-transparent"
                                                                                                   currencyMask
                                                                                                   [options]="{ prefix: '£ ' }"
                                                                                                   matInput
                                                                                                   autocomplete="off"
                                                                                                   placeholder="0.00"
                                                                                                   [value]="calculateScheduleTotals(budget, head)"
                                                                                                   disabled
                          />
                          </td>
                        </tr>
                      </ng-container>


                    </ng-container>


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </card-body>
  </app-cw-card>

  <aside
    class="sticky flex-col shrink-0 mr-5 ml-0 w-full order-first top-[-20px]">
    <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 mx-5 mt-5 ">
      <dl class="flex flex-wrap">
        <div class="flex-auto px-6 pt-6">
          <app-cw-secondary-button [buttonText]="'Edit ' + nextBudgetYear + ' Budget'"
                                   *ngIf="userGroups.includes('BudgetEdit')"
                                   (onClick)="goToEditBudget()"></app-cw-secondary-button>


        </div>

      </dl>
      <div
        class="mt-6 flex flex-col justify-center justify-items-stretch border-t border-gray-900/5 gap-x-2 px-6 py-6 w-full">

        <div class="mt-5 px-1 flex flex-col ">
          <div class="">
            <div class=" flow-root">
              <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block  py-2 align-middle px-6 w-full">
                  <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-300">
                      <tr>
                        <th scope="col"
                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[400px]">
                        </th>
                        <th scope="col" class="pr-6 py-3.5 text-right text-sm font-semibold text-gray-900 w-[150px]"
                        >
                          <select [(ngModel)]="selectedYears[0]" (ngModelChange)="updateSelectedBudgets()"
                                  class="border-none rounded-lg focus:ring-cw-blue bg-transparent cursor-pointer">
                            <option>-</option>
                            <option *ngFor="let budget of budgetYears" [value]="budget">{{ budget }}</option>
                          </select>
                        </th>
                        <th scope="col" class="pr-6 py-3.5 text-right text-sm font-semibold text-gray-900 w-[150px]"
                        >
                          <select [(ngModel)]="selectedYears[1]" (ngModelChange)="updateSelectedBudgets()"
                                  class="border-none rounded-lg focus:ring-cw-blue bg-transparent cursor-pointer ">
                            <option>-</option>
                            <option *ngFor="let budget of budgetYears" [value]="budget">{{ budget }}</option>
                          </select>
                        </th>
                        <th scope="col" class="pr-6 py-3.5 text-right text-sm font-semibold text-gray-900 w-[150px]"
                        >
                          <select [(ngModel)]="selectedYears[2]" (ngModelChange)="updateSelectedBudgets()"
                                  class="border-none rounded-lg focus:ring-cw-blue bg-transparent cursor-pointer">
                            <option *ngFor="let budget of budgetYears" [value]="budget">{{ budget }}</option>
                          </select>
                        </th>
                      </tr>
                      <tr class="divide-y divide-gray-200 bg-white">
                        <th scope="row"
                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[400px]">
                          Budget Total
                        </th>
                        <td *ngFor="let budget of selectedBudgets"
                            class=" px-3 py-1 text-xs text-gray-500 text-right w-[150px]">
                          <input *ngIf="budget"
                                 class="text-gray-900 w-[150px] border-none bg-transparent"
                                 currencyMask
                                 [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                                 matInput
                                 disabled
                                 [value]="calculateTotal(budget, 'BH')"
                          />
                        </td>
                      </tr>
                      <tr class="divide-y divide-gray-200 bg-white">
                        <th scope="row"
                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[400px]">
                          Reserves Total
                        </th>
                        <td *ngFor="let budget of selectedBudgets"
                            class=" px-3 py-1 text-xs text-gray-500 text-right w-[150px]">
                          <input *ngIf="budget"
                                 class="text-gray-900 w-[150px] border-none bg-transparent"
                                 currencyMask
                                 [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                                 matInput
                                 disabled
                                 [value]="calculateTotal(budget, 'SDH')"
                          />
                        </td>
                      </tr>
                      <tr class="divide-y divide-gray-200 bg-white">
                        <th scope="row"
                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[400px]">
                          Grand Total
                        </th>
                        <td *ngFor="let budget of selectedBudgets"
                            class=" px-3 py-1 text-xs text-gray-500 text-right w-[150px]">
                          <input *ngIf="budget"
                                 class="text-gray-900 w-[150px] border-none bg-transparent"
                                 currencyMask
                                 [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                                 matInput
                                 disabled
                                 [value]="calculateTotal(budget, '')"
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
        <!--        <app-banner *ngIf="messageBanner" [eventType]="messageBanner" [message]="messageText"></app-banner>-->


      </div>

    </div>


  </aside>
</div>

<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>

