import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    Audit,
    PropertyDetail,
    Transaction,
} from '../../../interfaces/property';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { get, isEqual, isObject, set, startCase, transform } from 'lodash';
import { PropertyDetailsService } from '../../../services/property-details/property-details.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
    AuditService,
    AuditTypes,
} from '../../../services/audit/audit.service';
import { ConfirmModalComponent } from '../../../components/modals/confirm-modal/confirm-modal.component';
import { ReversePipe } from 'ngx-pipes';
import { formatDate, Location } from '@angular/common';
import { isToday, setHours } from 'date-fns';
import { AuthServiceService } from '../../../services/auth-service/auth-service.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AppConfigService } from '../../../services/app-config/app-config.service';
import { countries } from 'countries-list';
import { MatDialog } from '@angular/material/dialog';
import { InfoModalComponent } from '../../../components/modals/info-modal/info-modal.component';

export interface SectionConfig {
    headerDetails: HeaderConfig;
    data: DataConfig[];
}

export interface HeaderConfig {
    section: string;
    icon: string;
    sectionName: string;
    subSections?: string[];
}

export interface DataConfig {
    fieldName: string;
    type: string;
    disabled: boolean;
    required: any[];
    subSection?: string;
    selectOptions?: string;
}

@Component({
    selector: 'app-property-result',
    templateUrl: './property-result.component.html',
    styleUrls: ['./property-result.component.css'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
        ReversePipe,
    ],
})
export class PropertyResultComponent implements OnInit {
    @ViewChild('fileUploadInput', { static: false }) fileInput!: ElementRef;
    @ViewChild('serviceChargeSelect', { static: false })
    serviceChargeSelect!: ElementRef;
    serviceCharges: string[] = [];
    selectSC: string | undefined;
    paymentButtons: boolean[] = [];
    startCase = startCase;
    messageBanner: 'SUCCESS' | 'ERROR' | undefined;
    showAudit: boolean[] = [];
    cameFrom!: string | null;
    emailRegex: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/;
    messageText: string = '';
    saving: boolean = false;
    propertyDetails!: PropertyDetail;
    propertyAudits: Audit[] = [];
    apiKey = environment.apiKey;
    apiBaseUrl = environment.apiBaseUrl;
    propertyFiles: string[] = [];
    propertyFolderPath!: string | '';
    propertyForm!: FormGroup;
    unitQuery: string = '';
    originalValuesForm: any;
    commentBox?: string;
    tabSelected: string = 'details';
    addingAudit: boolean = false;
    minPaymentDate: Date = new Date(
        new Date().setUTCDate(new Date().getDate() - 180)
    );
    transferOwner: boolean = false;
    countries: string[] = Object.values(countries)
        .map((value) => value.name)
        .sort();
    propertyConfig: SectionConfig[] = [];

    paymentArray: Transaction[] = [];
    accountBalance = 0;

    showAllPayments: boolean = false;
    userName!: string;
    userGroups!: (string | number)[];
    loading: boolean = true;

    // Payment
    paymentForm: FormGroup = new FormGroup({
        transactionType: new FormControl(
            { value: 'Payment', disabled: false },
            { validators: [Validators.required] }
        ),
        date: new FormControl(
            {
                value: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
                disabled: false,
            },
            { validators: [Validators.required] }
        ),
        description: new FormControl(
            { value: '', disabled: false },
            { validators: [Validators.required] }
        ),
        amount: new FormControl(
            { value: null, disabled: false },
            { validators: [Validators.required] }
        ),
    });
    paymentLoading: boolean = false;
    completed: boolean = false;
    maxDate: Date = new Date();
    minDate: Date = new Date(new Date().setUTCDate(new Date().getDate() - 7));

    // File Uploads
    uploading: boolean = false;
    progress: number = 0;
    upload$!: any;
    response: HttpResponse<any> | undefined;
    fileExists: boolean = false;
    fileToUpload: File | undefined;

    constructor(
        public router: Router,
        public propertyDetailsService: PropertyDetailsService,
        public propDetailsService: PropertyDetailsService,
        public auditService: AuditService,
        public dialog: MatDialog,
        public reversePipe: ReversePipe,
        public location: Location,
        private activatedRoute: ActivatedRoute,
        public auth: AuthServiceService,
        public http: HttpClient,
        public appConfig: AppConfigService
    ) {}

    /**
     * Angular Lifecycle event
     */
    async ngOnInit(): Promise<void> {
        const { from, unit } = await firstValueFrom(
            this.activatedRoute.queryParams
        );
        this.cameFrom = from;
        this.unitQuery = unit;

        this.propertyConfig = this.appConfig?.config?.property;

        try {
            this.loading = true;
            this.userName = await this.auth.getUserName();
            this.userGroups = await this.auth.getGroups();

            await Promise.all([
                this.getPropertyDetails(),
                this.updateFiles(),
                this.updateServiceCharges(),
                this.updatePaymentList(this.unitQuery),
                this.getAudits(this.unitQuery),
            ]);

            this.createForm();
            this.isPaymentAdmin();

            this.showAudit = new Array(this.propertyAudits.length).fill(false);

            this.propertyFolderPath =
                this.appConfig.selectedSite +
                '/' +
                this.propertyDetails?.unitFull.replace(/ /g, '_') +
                '/';

            if (!this.userGroups?.includes('PropertyDetailsFull'))
                this.propertyForm.disable();
        } catch (error: any) {
            console.error(error);
            this.dialog.open(InfoModalComponent, {
                width: '500px',
                data: {
                    message: 'There was an error retrieving property details',
                },
            });
            await this.router.navigateByUrl(
                this.cameFrom === 'search'
                    ? '/home-page/property-search'
                    : '/home-page/report-viewer'
            );
        } finally {
            this.loading = false;
        }
    }

    isPaymentAdmin() {
        if (this.userGroups.includes('PaymentAdmin')) {
            this.maxDate = new Date(
                new Date().setUTCDate(new Date().getDate() + 30)
            );
            this.minDate = new Date(
                new Date().setUTCDate(new Date().getDate() - 180)
            );
        }
    }

    async getAudits(unitFull: string) {
        this.propertyAudits =
            (await this.auditService.getAudits(unitFull)) ?? [];
    }

    /**
     * Call the Property Details Endpoint
     */
    async getPropertyDetails() {
        this.propertyDetails = await firstValueFrom(
            this.propDetailsService.callPropertyEndpoint(this.unitQuery)
        );
    }

    /**
     * Create the Form from the config
     */
    createForm() {
        if (!this.propertyConfig) return;
        this.propertyForm = new FormGroup({});

        for (let section of this.propertyConfig) {
            section.data.forEach((value: DataConfig) => {
                this.propertyForm.addControl(
                    value.fieldName,
                    new FormControl(
                        {
                            value:
                                value.type === 'date'
                                    ? this.formatFormDate(
                                          get(
                                              this.propertyDetails,
                                              `${section.headerDetails.sectionName}${value?.subSection || ''}${value.fieldName}`
                                          )
                                      )
                                    : get(
                                          this.propertyDetails,
                                          `${section.headerDetails.sectionName}${value?.subSection || ''}${value.fieldName}`
                                      ),
                            disabled: value.disabled,
                        },
                        {
                            validators: [
                                ...(value.required.includes('required')
                                    ? [Validators.required]
                                    : []),
                                ...(value.required.includes('email')
                                    ? [Validators.email]
                                    : []),
                                ...(value.required.find((val) =>
                                    val.startsWith('^[')
                                )
                                    ? [
                                          Validators.pattern(
                                              value.required.find((val) =>
                                                  val.startsWith('^[')
                                              )
                                          ),
                                      ]
                                    : []),
                            ],
                        }
                    )
                );
            });
        }

        this.originalValuesForm = this.propertyForm.getRawValue();
    }

    formatFormDate(value: undefined | string) {
        if (!value) return undefined;
        return formatDate(value, 'yyyy-MM-dd', 'en');
    }

    /**
     * Get the Payment List for unit
     * @param unitFull
     */
    async updatePaymentList(unitFull: string) {
        if (!this.userGroups?.includes('PropertyDetailsFull')) return;

        this.paymentArray = (
            await firstValueFrom(
                this.propDetailsService.getPaymentList(unitFull)
            )
        ).sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );

        this.accountBalance = this.paymentArray.reduce((total, trans) => {
            return ['Charge', 'Refund Payment'].includes(trans.transactionType)
                ? total + trans.amount
                : total - trans.amount;
        }, 0);

        this.paymentButtons.fill(false, 0, this.paymentArray.length);
    }

    /**
     * Get file list for unit
     */
    updateFiles = async (): Promise<void> => {
        this.propertyFiles = await firstValueFrom(
            this.propDetailsService.getFileList(this.unitQuery)
        );
    };

    /**
     * Get Service charges for unit
     */
    async updateServiceCharges() {
        this.serviceCharges = await firstValueFrom(
            this.propertyDetailsService.getFileList(null, true)
        );
        this.serviceCharges.sort((a, b) =>
            +b.split('_')[1] - +a.split('_')[1] === 0
                ? b.localeCompare(a)
                : +b.split('_')[1] - +a.split('_')[1]
        );
    }

    /**
     * Reset form to stored values
     */
    resetForm = (): void => {
        for (let section of this.propertyConfig) {
            section.data.forEach((value: DataConfig) => {
                this.propertyForm.controls[value.fieldName].setValue(
                    get(
                        this.propertyDetails,
                        `${section.headerDetails.sectionName}${value?.subSection || ''}${value.fieldName}`
                    ),
                    {
                        onlySelf: true,
                        emitEvent: false,
                    }
                );
            });
        }
        this.transferOwner = false;
        this.propertyForm.markAsPristine();
    };

    /**
     * Deep diff between two object, using lodash
     * @param  {Object} object Object compared
     * @param  {Object} base   Object to compare with
     * @return {Object}        Return a new object who represent the diff
     */
    difference = (object: object, base: object): object => {
        function changes(object: object, base: { [x: string]: any }) {
            return transform(
                object,
                function (result: object, value, key: string) {
                    if (!isEqual(value, base[key])) {
                        set(
                            result,
                            key,
                            isObject(value) && isObject(base[key])
                                ? changes(value, base[key])
                                : value
                        );
                    }
                }
            );
        }

        return changes(object, base);
    };

    /**
     * Save new values
     */
    saveChanges = async (): Promise<any> => {
        this.saving = true;
        this.loading = true;

        let propertyDetails = this.propertyForm.getRawValue();
        let mappedDetails: PropertyDetail = {} as PropertyDetail;

        for await (let section of this.propertyConfig) {
            section.data.forEach((value: DataConfig) => {
                if (this.propertyForm.get(value.fieldName)?.dirty) {
                    set(
                        mappedDetails,
                        `${section.headerDetails.sectionName}${value?.subSection || ''}${value.fieldName}`,
                        propertyDetails[value.fieldName]
                    );
                }
            });
        }

        mappedDetails.unitFull = this.propertyDetails.unitFull;
        mappedDetails.site = this.propertyDetails.site;

        let updateDiff = this.difference(mappedDetails, this.propertyDetails);

        try {
            const submitResponse = await firstValueFrom(
                this.propertyDetailsService.callUpdatePropertyEndpoint(
                    mappedDetails
                )
            );

            await this.auditService.createAudit(
                this.transferOwner
                    ? AuditTypes.TransferOwner
                    : AuditTypes.UpdateDetails,
                `Updated the following fields: ${JSON.stringify(this.getDirtyFormValues())}`,
                this.propertyDetails.unitFull
            );
            this.showMessageBanner('Successfully Updated', 'SUCCESS');

            await Promise.all([
                this.getPropertyDetails(),
                this.getAudits(this.propertyDetails.unitFull),
            ]);

            this.originalValuesForm = this.propertyForm.getRawValue();

            this.transferOwner = false;
            this.propertyForm.markAsPristine();
        } catch (error: any) {
            console.error(error);
            this.showMessageBanner('An Error Occurred ', 'ERROR');
        } finally {
            this.saving = false;
            this.loading = false;
        }
    };

    getDirtyFormValues() {
        let updatedValues = {};
        for (const control in this.propertyForm.controls) {
            if (this.propertyForm.controls[control].dirty) {
                updatedValues = {
                    ...updatedValues,
                    [control]: {
                        original: this.originalValuesForm[control],
                        new: this.propertyForm.controls[control].value,
                    },
                };
            }
        }
        return updatedValues;
    }

    /**
     * Show Message banner
     *
     * @param text
     * @param type
     */
    showMessageBanner = (text: string, type: 'SUCCESS' | 'ERROR'): void => {
        this.messageText = text;
        this.messageBanner = type;
        setTimeout(() => {
            this.messageBanner = undefined;
            this.messageText = '';
        }, 5000);
    };

    /**
     * * Delete audit
     * @param audit
     */
    removeAudit = async (audit: Audit) => {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: { message: 'Are you sure you want to delete this note?' },
        });
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                try {
                    this.loading = true;
                    const [unitFull, timestamp] =
                        audit['unitFull#timestamp'].split('#');
                    await this.auditService.deleteAudit(unitFull, timestamp);

                    this.showMessageBanner('Comment Removed', 'SUCCESS');
                    await this.getAudits(this.propertyDetails.unitFull);
                } catch (error: any) {
                    console.error(error.message);
                    this.showMessageBanner('Failed deleting comment', 'ERROR');
                } finally {
                    this.loading = false;
                }
            }
        });
    };

    addAudit = async () => {
        if (!this.commentBox || this.commentBox.length < 1) return;
        this.loading = true;

        try {
            const response = await this.auditService.createAudit(
                AuditTypes.Note,
                this.commentBox,
                this.propertyDetails.unitFull
            );

            this.showMessageBanner('Comment Added', 'SUCCESS');
            this.commentBox = undefined;
            await this.getAudits(this.propertyDetails.unitFull);
        } catch (error: any) {
            console.error(error.message);
            this.showMessageBanner('Failed to add comment', 'ERROR');
        } finally {
            this.loading = false;
        }
    };

    /**
     * * View file
     * @param fileName
     */
    openFile(fileName: string): void {
        const windowRef = window.open();

        firstValueFrom(
            this.propertyDetailsService.getPresignedUrl(fileName, 'Get')
        ).then((url) => {
            if (windowRef) windowRef.location = url;
        });
    }

    /**
     * * View file
     * @param fileName
     */
    openServiceCharge(fileName: string): void {
        const windowRef = window.open();
        const filePath = `${fileName}/${this.propertyDetails.unitFirst}/${this.propertyDetails.unitFull}.pdf`;

        firstValueFrom(
            this.propertyDetailsService.getServiceCharge(filePath, 'Get')
        ).then((url) => {
            if (windowRef) windowRef.location = url;
        });
    }

    /**
     * Delete file for property
     * @param file
     */
    deleteFile(file: string): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: { message: 'Are you sure you want to delete this file?' },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                try {
                    this.loading = true;
                    await firstValueFrom(
                        this.propertyDetailsService.deleteFile(file)
                    );
                    await this.auditService.createAudit(
                        AuditTypes.DeleteFile,
                        `${file}`,
                        this.propertyDetails.unitFull
                    );

                    await Promise.all([
                        this.updateFiles(),
                        this.getAudits(this.propertyDetails.unitFull),
                    ]);
                    this.showMessageBanner('File deleted', 'SUCCESS');
                } catch (error: any) {
                    console.error(error.message);
                    this.showMessageBanner('Failed to delete file', 'ERROR');
                } finally {
                    this.loading = false;
                }
            }
        });
    }

    async canDeactivate() {
        return new Promise((resolve) => {
            if (this.propertyForm?.dirty) {
                const dialogRef = this.dialog.open(ConfirmModalComponent, {
                    width: '500px',
                    data: {
                        message:
                            'You have unsaved changes that will be lost, are you sure?',
                    },
                });

                dialogRef.afterClosed().subscribe(async (result) => {
                    if (!result) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
            } else {
                resolve(true);
            }
        });
    }

    async deleteTransaction(id: string, message: string) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
                message: `Are you sure you want to delete this Transaction? \n ${message}`,
            },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                try {
                    this.loading = true;
                    await firstValueFrom(
                        this.propDetailsService.removeTransaction(
                            id,
                            this.propertyDetails.unitFull
                        )
                    );

                    await this.auditService.createAudit(
                        AuditTypes.deleteTransaction,
                        `${id}, ${message}`,
                        this.propertyDetails.unitFull
                    );
                    await Promise.all([
                        this.updatePaymentList(this.propertyDetails.unitFull),
                        this.getAudits(this.propertyDetails.unitFull),
                    ]);

                    this.showMessageBanner('Transaction deleted', 'SUCCESS');
                } catch (error: any) {
                    console.error(error);
                    this.showMessageBanner(
                        'Failed deleting Transaction',
                        'ERROR'
                    );
                } finally {
                    this.loading = false;
                }
            }
        });
    }

    isToday(paymentDate: string): boolean {
        return isToday(new Date(paymentDate));
    }

    dateBeforeMin(transDate: string): boolean {
        return this.minPaymentDate.getTime() > new Date(transDate).getTime();
    }

    isAutoCharge(desc: string): boolean {
        return [
            'Service Charge Demand',
            'Reserve Contributions',
            'Balancing Charges for year ending',
            'Boiler Servicing Scheme',
            'Balance from Ringley',
        ].some((charge) => desc.includes(charge));
    }

    calculateBalance = (
        transactions: Transaction[] | undefined,
        index: number
    ) => {
        return (
            transactions?.slice(index).reduce((total, trans) => {
                return ['Charge', 'Refund Payment'].includes(
                    trans.transactionType
                )
                    ? total + trans.amount
                    : total - trans.amount;
            }, 0) ?? 0
        ).toFixed(2);
    };

    async addTransaction() {
        try {
            this.loading = true;
            const origDate = this.paymentForm.controls['date'].value;
            const date = setHours(new Date(origDate), 12);
            const { transactionType, description } =
                this.paymentForm.getRawValue();
            const refund: boolean = [
                'Refund Charge',
                'Refund Payment',
            ].includes(transactionType);

            await firstValueFrom(
                this.propertyDetailsService.addTransaction(
                    {
                        ...this.paymentForm.getRawValue(),
                        description: refund
                            ? '**REFUND** ' + description
                            : description,
                        date,
                    },
                    this.propertyDetails.unitFull
                )
            );

            await this.auditService.createAudit(
                AuditTypes.AddTransaction,
                `${transactionType}, ${description}`,
                this.propertyDetails.unitFull
            );

            await Promise.all([
                this.updatePaymentList(this.propertyDetails.unitFull),
                this.getAudits(this.propertyDetails.unitFull),
            ]);

            this.completed = true;
            this.paymentForm.reset({
                transactionType: 'Payment',
                date: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
            });

            this.showMessageBanner('Transaction added', 'SUCCESS');
        } catch (error: any) {
            console.error(error);
            this.showMessageBanner('Failed to add Transaction', 'ERROR');
        } finally {
            this.loading = false;
        }
    }

    /**
     * Upload File to AWS, If file does not already exist with name
     *
     * @param file
     */
    uploadFile = async (file: File | undefined): Promise<any> => {
        if (!file) return;
        const newFileName: string = file.name.replace(/ /g, '_');
        const key: string = this.propertyFolderPath + newFileName;

        if (this.propertyFiles.includes(newFileName)) {
            this.fileExists = true;
            return;
        } else {
            this.fileExists = false;
        }

        try {
            this.loading = true;
            const url: string = await firstValueFrom(
                this.propertyDetailsService.getPresignedUrl(key, 'Put')
            );

            await lastValueFrom(
                this.propertyDetailsService.fileUpload(url, key, file)
            );

            await this.updateFiles();
            await this.auditService.createAudit(
                AuditTypes.UploadFile,
                `${file.name}`,
                this.propertyDetails.unitFull
            );
            this.fileToUpload = undefined;
            this.fileInput.nativeElement.value = null;
            await this.getAudits(this.propertyDetails.unitFull);

            this.showMessageBanner('File uploaded', 'SUCCESS');
        } catch (error: any) {
            console.error(error);
            this.showMessageBanner('Failed to upload file', 'ERROR');
        } finally {
            this.loading = false;
        }
    };

    /**
     * Cancel an upload in progress
     */
    cancelUpload = (): void => {
        if (this.upload$) this.upload$.unsubscribe();
    };

    isCorrectType = (fileTypes: string[], file: File): boolean => {
        return fileTypes.includes(file.type);
    };

    handleChange(event: any) {
        this.fileToUpload = event.target.files[0] as File;

        const newFileName: string = this.fileToUpload.name.replace(/ /g, '_');
        const key: string = this.propertyFolderPath + newFileName;

        if (this.propertyFiles.includes(key)) {
            this.fileExists = true;
            return;
        } else {
            this.fileExists = false;
        }
    }

    async updateDemand(serviceCharge: string | undefined) {
        if (!serviceCharge) return;
        try {
            this.loading = true;
            const budgetMonth = serviceCharge!.split('_')[0] as
                | 'march'
                | 'september';
            const selectedYear = +serviceCharge!.split('_')[1];
            const budgetYear =
                budgetMonth === 'march'
                    ? `${selectedYear - 1}-${selectedYear}`
                    : `${selectedYear}-${selectedYear + 1}`;
            await firstValueFrom(
                this.propertyDetailsService.updateServiceCharge(
                    budgetYear,
                    this.propertyDetails.unitFull,
                    budgetMonth
                )
            );
            await this.auditService.createAudit(
                AuditTypes.UpdateServiceCharge,
                `${serviceCharge}`,
                this.propertyDetails.unitFull
            );

            await this.getAudits(this.propertyDetails.unitFull);
            this.showMessageBanner('Service Charge updated', 'SUCCESS');
        } catch (error: any) {
            console.error(error);
            this.showMessageBanner('Failed to update Service Charge', 'ERROR');
        } finally {
            this.loading = false;
        }
    }

    async transferOwnership() {
        this.tabSelected = 'details';
        this.transferOwner = true;

        const section = this.propertyConfig.find(
            (section) => section.headerDetails.section === 'Owner Details'
        );
        if (!section) return;

        section.data.forEach((value: DataConfig) => {
            this.propertyForm.controls[value.fieldName].patchValue('', {
                onlySelf: true,
                emitEvent: false,
            });
            this.propertyForm.controls[value.fieldName].markAsDirty();
            this.propertyForm.controls[value.fieldName].markAsTouched();
            this.propertyForm.controls[
                value.fieldName
            ].updateValueAndValidity();
        });

        this.propertyForm.markAsDirty();
    }
}
